import animationFadeIn from "./animation-fade-in.js";
import animationVeggieTiles from "./animation-veggie-tiles.js";
import roundCornersCalendarDateSpan from "./round-corners-calendar-date-span.js";

document.addEventListener("DOMContentLoaded", () => {
  /*
  The DOMContentLoaded event fires when the initial HTML document has 
  been completely loaded and parsed, without waiting for stylesheets, 
  images, and subframes to finish loading.
  */
  roundCornersCalendarDateSpan();
});

window.addEventListener("load", () => {
  /*
  The load event is fired when the whole page has loaded, 
  including all dependent resources such as stylesheets and images
  */
  animationFadeIn();
  animationVeggieTiles();
});
