import { gsap } from "gsap";

function animationVeggieTiles() {
  const io = new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const svg = entry.target.children[0];
          gsap.to(svg, {
            scale: 1,
            rotation: 0,
            duration: 0.8,
            ease: "back",
            delay: Math.random() / 2,
          });
          unobserverElement(svg);
        }
      });
    },
    { threshold: 0.5 }
  );

  const veggieTileIcons = Array.from(
    document.querySelectorAll(".veggie-tiles__icon-animation")
  );

  veggieTileIcons.forEach((element) => {
    io.observe(element);
  });

  function unobserverElement(element) {
    io.unobserve(element);
  }
}

export default animationVeggieTiles;
