import { gsap } from "gsap";

function animationFadeIn() {
  const io = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        gsap.to(entry.target, {
          opacity: 1,
          duration: 1,
        });
        unobserverElement(entry.target);
      }
    });
  });

  const fadeIn = Array.from(document.querySelectorAll(".a-fade-in"));

  fadeIn.forEach((element) => {
    io.observe(element);
  });

  function unobserverElement(element) {
    io.unobserve(element);
  }
}

export default animationFadeIn;
