function roundCornersCalendarDateSpan() {
  const rows = Array.from(document.querySelectorAll(".calendar-table__row"));

  rows.forEach((row) => {
    const dataCells = Array.from(row.querySelectorAll(".calendar-table__data"));
    dataCells.forEach((cell, index) => {
      hasRoundCorners("saison");
      hasRoundCorners("stored");

      function hasRoundCorners(type) {
        if (cell.classList.contains(`calendar-table__data--${type}`)) {
          const isTheLastElement =
            index <= 10 &&
            !cell.nextElementSibling.classList.contains(
              `calendar-table__data--${type}`
            );

          const seasonStartsBeforeJanuary =
            index === 0 &&
            !dataCells[11].classList.contains(`calendar-table__data--${type}`);
          const isTheFirstElement =
            (index >= 1 &&
              !cell.previousElementSibling.classList.contains(
                `calendar-table__data--${type}`
              )) ||
            seasonStartsBeforeJanuary;

          if (isTheLastElement) {
            cell.classList.add(`calendar-table__data--${type}-rounded-right`);
          }

          if (isTheFirstElement) {
            cell.classList.add(`calendar-table__data--${type}-rounded-left`);
          }
        }
      }
    });
  });
}
export default roundCornersCalendarDateSpan;
